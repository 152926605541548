import ApiService from '@/core/services/api.service';
import groupBy from 'lodash/groupBy';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const GET_USER = 'getUser';
export const UPDATE_USER = 'updateUser';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const CHANGE_STATUS = 'CHANGE_STATUS';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_ERROR = 'setError';

const $api = new ApiService();

const state = {
  errors: null,
  user: {},
  isAuthenticated: true,
  permissions: [],
};
const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      $api
        .post('login', credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      $api
        .post('users', { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [GET_USER](context) {
    return new Promise((resolve) => {
      $api
        .get('Admin/Account/Current')
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response?.data.errors);
        });
    });
  },
  [GET_PERMISSIONS](context) {
    return new Promise((resolve) => {
      $api
        .get('PermissionRole/CurrentUser')
        .then(({ data }) => {
          const groupPermissionByCode = groupBy(data, 'group');
          const permissions = [];
          for (const property in groupPermissionByCode) {
            permissions.push({
              code: property,
              value: groupPermissionByCode[property],
            });
          }
          context.commit(SET_PERMISSIONS, permissions);
          localStorage.setItem('permissions', JSON.stringify(permissions));
          resolve(permissions);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response?.data.errors);
          resolve(response);
        });
    });
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return $api.put('user', user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
  [CHANGE_STATUS]: async (context, params) => {
    try {
      const { meta, error } = await $api.put(
        `Admin/Account/portal/${params.id}/State`,
        params,
      );

      if (!meta.success) {
        return { error: error, data: null };
      }

      return { error: null, data: null };
    } catch (error) {
      return { error: error, data: null };
    }
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    state.permissions = [];
  },
  [SET_PERMISSIONS]: (state, permissions) => {
    state.permissions = permissions;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
