<template>
  <div>
    <b-form-group
      :id="`${name}-group`"
      :description="description"
      :label="label"
      :label-for="`${name}-ID`"
      :invalid-feedback="invalidFeedback"
      :valid-feedback="validFeedback"
      :state="state"
      :class="`${required ? 'required' : ''}`"
    >
      <div class="d-flex">
        <input
          v-if="code"
          type="text"
          class="form-control w-25"
          disabled
          :value="code"
          :class="{ 'unborder-right': code }"
        />
        <b-form-input
          v-if="!inputNumber"
          v-bind="$attrs"
          :id="`${name}-ID`"
          :name="`${name}`"
          :value="value"
          :state="state"
          :type="inputType"
          trim
          :placeholder="placeholder"
          :debounce="debounce"
          class=""
          :class="{ 'unborder-left': code }"
          @change="valueChange"
          v-mask="mask"
          :disabled="disabled"
          @update="valueUpdate"
        ></b-form-input>
        <b-form-input
          v-else
          v-bind="$attrs"
          :id="`${name}-ID`"
          :name="`${name}`"
          :value="value"
          :state="state"
          :type="inputType"
          trim
          :placeholder="placeholder"
          :debounce="debounce"
          :class="{ 'unborder-left': code }"
          @change="valueChange"
          v-mask="mask"
          :disabled="disabled"
          @update="valueUpdate"
          @keydown.native="checkValidNumber($event)"
          @paste.native="handleOnPaste($event)"
        ></b-form-input>
      </div>
    </b-form-group>
  </div>
</template>

<script>
export default {
  // computed: {
  // },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, null],
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    invalidFeedback: {
      type: String,
      default: null,
    },
    validFeedback: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    code: {
      type: String,
      default: null,
    },
    debounce: {
      type: Number,
      default: 0,
    },
    inputNumber: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    mask() {
      let mask = null;
      if (this.type === 'phone_number') {
        mask = '+84 ### ### ###';
      }
      return mask;
    },
  },
  methods: {
    valueChange($event) {
      const value = $event ? $event.trim() : null;
      this.$emit('update:value', value);
      this.$emit('input', value);
    },
    valueUpdate($event) {
      const value = $event ? $event.trim() : null;
      this.$emit('v-modal:update', value);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.unborder-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.unborder-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control:disabled {
  background: #f8fafb;
}
</style>
