import ApiService from '@/core/services/api.service';

// action types
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const CREATE_SECTION = 'CREATE_SECTION';

// mutation types
export const SET_STATE_MODAL = 'SET_STATE_MODAL';

const $api = new ApiService();
const state = {
  showModal: false,
};

const getters = {};

const actions = {
  [CHANGE_STATUS]: async (context, params = {}) => {
    try {
      const { meta, data, error } = await $api.put(
        `/Agenda/${params.id}/State`,
        params,
      );
      if (!meta.success) {
        return { error, data: null };
      }

      return { error: null, data: data };
    } catch (error) {
      return { error: error, data: null };
    }
  },
  [CREATE_SECTION]: async (context, params = {}) => {
    try {
      const { meta, data, error } = await $api.post(
        `/ExerciseMovement/Sections`,
        params,
      );
      if (!meta.success) {
        return { error: error.message, data: null };
      }

      return { error: null, data: data };
    } catch (error) {
      return { error: error, data: null };
    }
  },
};

const mutations = {
  [SET_STATE_MODAL]: (state, payload) => {
    state.showModal = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
