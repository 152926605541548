import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import vi from 'vee-validate/dist/locale/vi';
import {
  checkInvalidVideoUrl,
  checkInvalidAudioUrl,
  convertTimeToSecond,
} from '../mixins';
Validator.localize('vi', vi);

Validator.extend('phone', {
  getMessage: (field) => `${field} không hợp lệ`,
  validate: (value) => {
    const valid = /^[0-9]{9,10}$/;
    return valid.test(value);
  },
});

Validator.extend('year', {
  getMessage: (field) => `${field} không hợp lệ`,
  validate: (value) => {
    const valid = /^\d*$/;
    return valid.test(value);
  }
});

Validator.extend('duplicate_phone', {
  getMessage: (field) => `${field} không được trùng số điện thoại 1`,
  validate: (value, [arg]) => {
    return value !== arg;
  },
});

Validator.extend('alpha_num_dot', {
  getMessage: (field) =>
    `${field} không được chứa ký hiệu đặc biệt hoặc nhiều dấm chấm (.) liên tiếp`,
  validate: (value) => {
    const valid = /^[a-zA-Z0-9]*\.?[a-zA-Z0-9]*$/;
    return valid.test(value);
  },
});

Validator.extend('valid_link_zalo', {
  getMessage: (field) => `${field} không hợp lệ`,
  validate: (value) => {
    //eslint-disable-line
    //eslint-disable-next-line
    const valid = /^(https|http):\/\/(chat.zalo.me|zalo.me)\/(?:(?:\w\.)*#!\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/gi.test(
        value,
      );
    return valid && !value.includes(' ');
  },
});

Validator.extend('valid_link_zoom', {
  getMessage: (field) => `${field} không hợp lệ`,
  validate: (value) => {
    //eslint-disable-line
    //eslint-disable-next-line
    const valid = /^(https|http):\/\/zoom.us\/(?:(?:\w\.)*#!\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/gi.test(
        value,
      );
    return valid && !value.includes(' ');
  },
});

Validator.extend('valid_free_day', {
  getMessage: (field) => field + ' cần nhỏ hơn Số ngày.',
  validate: ({ value }, [arg]) => {
    return !(Number(value) > Number(arg));
  },
});

Validator.extend('valid_ranger_start_BMI', {
  getMessage: (field) => field + ' cần nhỏ hơn BMI đến.',
  validate: ({ value }, [arg]) => {
    return !(Number(value) > Number(arg));
  },
});

Validator.extend('valid_ranger_end_BMI', {
  getMessage: (field) => field + ' cần lớn hơn BMI từ.',
  validate: ({ value }, [arg]) => {
    return Number(value) > Number(arg);
  },
});

Validator.extend('valid_code', {
  getMessage: (field) => field + ' đã tồn tại.',
  validate: () => {
    return false;
  },
});
Validator.extend('valid_video_url', {
  getMessage: (field) => field + ' không hợp lệ.',
  validate: async (value) => {
    const isValid = await checkInvalidVideoUrl(value);
    return isValid;
  },
});
Validator.extend('valid_audio_url', {
  getMessage: (field) => field + ' không hợp lệ.',
  validate: async (value) => {
    const isValid = await checkInvalidAudioUrl(value);
    return isValid;
  },
});
Validator.extend('more_than_zero', {
  getMessage: (field) => field + ' phải lớn hơn 0.',
  validate: async (value) => {
    return Number(value) > 0;
  },
});
Validator.extend(
  'min_time',
  {
    getMessage: (field, args) => `${field} phải sau ${args}`,
    validate: (value, [otherValue]) => {
      if (!value || !otherValue) return true;

      const fromTime = convertTimeToSecond(value);
      const toTime = convertTimeToSecond(otherValue);
      return fromTime > toTime;
    },
  },
  {
    hasTarget: true,
  },
);
Validator.extend(
  'max_time',
  {
    getMessage: (field, args) => `${field} phải trước ${args}`,
    validate: (value, [otherValue]) => {
      if (!value || !otherValue) return true;

      const fromTime = convertTimeToSecond(value);
      const toTime = convertTimeToSecond(otherValue);
      return fromTime < toTime;
    },
  },
  {
    hasTarget: true,
  },
);
Validator.extend('valid_question_code', {
  getMessage: (field) => field + ' chỉ có thể chứa các kí tự chữ, số và "_".',
  validate: (value) => {
    const regex = /^(([a-zA-Z0-9]{6})_[a-zA-Z0-9]{1,})$|^[a-zA-Z0-9]{1,}$/;
    return regex.test(value);
  },
});

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'errors',
  events: 'change|blur|select',
});
