import ApiService from '@/core/services/api.service';
import { orderBy } from 'lodash';

// action types
export const GET_PREPARE = 'GET_PREPARE';
export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENTS_GROUP = 'GET_PATIENTS_GROUP';
export const GET_GROUP_COACH = 'GET_GROUP_COACH';
export const DELETE_PATIENTS = 'DELETE_PATIENTS';
export const CHANGE_GROUP = 'CHANGE_GROUP';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const SORT_PATIENT = 'SORT_PATIENT';

// mutation types
export const SET_SELECTED_PATIENTS = 'SET_SELECTED_PATIENTS';
export const GET_PREPARE_SUCCESS = 'GET_PREPARE_SUCCESS';
export const GET_PREPARE_FAILED = 'GET_PREPARE_FAILED';
export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS';
export const GET_PATIENTS_FAILED = 'GET_PATIENTS_FAILED';
export const GET_GROUP_COACH_SUCCESS = 'GET_GROUP_COACH_SUCCESS';
export const GET_GROUP_COACH_FAILED = 'GET_GROUP_COACH_FAILED';
export const DELETE_PATIENTS_SUCCESS = 'DELETE_PATIENTS_SUCCESS';
export const SET_MODAL = 'SET_MODAL';
export const RESET_STATE = 'RESET_STATE';

const $api = new ApiService();

const state = {
  patients: [],
  selectedPatients: [],
  coaches: [],
  groupDetail: null,
  groupCoach: null,
  modalChangeGroup: false,
};
const getters = {
  getGroupCoachGeneral: (state) => {
    if (!state.groupCoach) return null;
    return {
      patientTotalCount: state.groupCoach.patientTotalCount,
      joinTwoWeekRecently: state.groupCoach.joinTwoWeekRecently,
      endTwoWeekComing: state.groupCoach.endTwoWeekComing,
      lowNumberOfDiabetics: state.groupCoach.lowNumberOfDiabetics,
      highNumberOfDiabetics: state.groupCoach.highNumberOfDiabetics,
      completeCourseLessHalf: state.groupCoach.completeCourseLessHalf,
      completeGoalLessHalf: state.groupCoach.completeGoalLessHalf,
    };
  },
};

const actions = {
  [GET_PREPARE]: async ({ commit }, params) => {
    try {
      const { data, meta } = await $api.get(
        '/TrainingGroup/PrepareCreateUpdateFormItem',
        {
          params,
        },
      );
      if (meta.success) {
        commit(GET_PREPARE_SUCCESS, data);
        return { data, error: null };
      }
      return { error: true, data: null };
    } catch (error) {
      commit(GET_PREPARE_FAILED);
      return { error: error, data: null };
    }
  },
  [GET_PATIENTS]: async ({ commit }, params) => {
    try {
      const { data, meta } = await $api.get('/TrainingGroup/SearchAccount', {
        params,
      });
      if (meta.success) {
        commit(GET_PATIENTS_SUCCESS, data);

        return { error: null, meta };
      }
    } catch (error) {
      commit(GET_PATIENTS_FAILED);
      return { error: error, meta: null };
    }
  },
  [DELETE_PATIENTS]: ({ commit }, params) => {
    commit(DELETE_PATIENTS_SUCCESS, params);
  },
  [GET_GROUP_COACH]: async ({ commit }, params) => {
    try {
      const { meta, error, data } = await $api.get(
        `/TrainingGroup/${params.id}`,
      );
      if (!meta.success) {
        return { error: error.message, data: null };
      }
      commit(GET_GROUP_COACH_SUCCESS, data);
      return { error: false, data };
    } catch (error) {
      commit(GET_GROUP_COACH_FAILED);
      return { error: error.message, data: null };
    }
  },
  [GET_PATIENTS_GROUP]: async (context, params) => {
    try {
      const res = await $api.get('/TrainingGroup/Detail', { params });
      if (![200, 201].includes(res.statusCode)) {
        return { error: res.message, data: null };
      }
      return { error: false, data: res.data };
    } catch (error) {
      return { error: error, data: null };
    }
  },
  [CHANGE_GROUP]: async (context, params) => {
    try {
      let payload = new FormData();
      for (const property in params) {
        payload.append(property, params[property]);
      }
      const res = await $api.post('/TrainingGroup/ChangeGroup', payload);
      if (![200, 201].includes(res.statusCode)) {
        return { error: res.message, data: null };
      }
      return { error: false, data: res.data };
    } catch (error) {
      return { error: error.message, data: null };
    }
  },
  [CHANGE_STATUS]: async (context, params) => {
    try {
      const { meta, error } = await $api.put(
        `/TrainingGroup/${params.id}/State`,
        params,
      );
      if (!meta.success) {
        return { error: error.message, data: null };
      }

      return { error: false, data: null };
    } catch (error) {
      return { error: error, data: null };
    }
  },
  [SORT_PATIENT]: ({ commit, state }, params) => {
    try {
      const patients = orderBy(
        state.selectedPatients,
        [params.column],
        [params.order],
      );
      commit(SET_SELECTED_PATIENTS, patients);
    } catch (error) {
      commit(SET_SELECTED_PATIENTS, []);
    }
  },
};

const mutations = {
  [SET_SELECTED_PATIENTS]: (state, payload) => {
    state.selectedPatients = payload;
  },
  [GET_PREPARE_SUCCESS]: (state, payload) => {
    state.coaches = payload.healthCoaches;
    state.selectedPatients = payload.patients || [];
    if (payload.code) {
      state.groupDetail = payload;
    }
  },
  [GET_PREPARE_FAILED]: (state) => {
    state.coaches = [];
    state.selectedPatients = [];
  },
  [GET_PATIENTS_SUCCESS]: (state, payload) => {
    state.patients = payload;
  },
  [GET_PATIENTS_FAILED]: (state) => {
    state.patients = [];
  },
  [DELETE_PATIENTS_SUCCESS]: (state, payload) => {
    state.selectedPatients = payload.selectedPatients;
  },
  [GET_GROUP_COACH_SUCCESS]: (state, payload) => {
    state.groupCoach = payload;
  },
  [GET_GROUP_COACH_FAILED]: (state) => {
    state.groupCoach = null;
  },
  [SET_MODAL]: (state, [variable, value]) => {
    state[variable] = value;
  },
  [RESET_STATE]: (state) => {
    state.patients = [];
    state.selectedPatients = [];
    state.coaches = [];
    state.groupDetail = null;
    state.groupCoach = null;
    state.modalChangeGroup = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
