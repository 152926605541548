<template>
  <div class="w-100">
    <b-container fluid class="mb-6 mt-6">
      <b-row>
        <b-col>
          <div id="wrapper" class="card card-custom gutter-b">
            <div class="card-body mt-0">
              <slot />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Wrapper',
};
</script>

<style></style>
