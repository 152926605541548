import ApiService from '@/core/services/api.service';

// action types
export const GET_DATA_PREPARE = 'GET_DATA_PREPARE';
export const GET_DATA_PREPARE_LIST = 'GET_DATA_PREPARE_LIST';
export const CREATE_QUIZE = 'CREATE_QUIZE';
export const UPDATE_QUIZE = 'UPDATE_QUIZE';
export const GET_QUIZES = 'GET_QUIZES';
export const CHANGE_STATUS = 'CHANGE_STATUS';

// mutation types
export const GET_DATA_PREPARE_SUCCESS = 'GET_DATA_PREPARE_SUCCESS';
export const GET_DATA_PREPARE_FAILED = 'GET_DATA_PREPARE_FAILED';

const $api = new ApiService();

const state = {
  dataPrepare: null,
};
const getters = {};

const actions = {
  [GET_DATA_PREPARE]: async ({ commit }, params = {}) => {
    try {
      const res = await $api.get('/Quiz/PrepareCreateUpdateFormItem', {
        params,
      });
      if (![200, 201].includes(res.statusCode)) {
        return { error: res.message, data: null };
      }
      commit(GET_DATA_PREPARE_SUCCESS, res.data);
    } catch (error) {
      commit(GET_DATA_PREPARE_FAILED);
      return { error: error, data: null };
    }
  },
  [CREATE_QUIZE]: async (context, params = {}) => {
    try {
      const res = await $api.post('/Quiz', params);
      if (![200, 201].includes(res.statusCode)) {
        return { error: res.message, data: null };
      }
      return { error: false, data: res.data };
    } catch (error) {
      return { error: error, data: null };
    }
  },
  [UPDATE_QUIZE]: async (context, params = {}) => {
    try {
      const res = await $api.put('/Quiz/Input', params);
      if (![200, 201].includes(res.statusCode)) {
        return { error: res.message, data: null };
      }
      return { error: false, data: res.data };
    } catch (error) {
      return { error: error, data: null };
    }
  },
  [GET_QUIZES]: async ({ commit }, params = {}) => {
    try {
      commit('context/setLoading', true, { root: true });
      const res = await $api.get('/Quiz', { params });
      if (![200, 201].includes(res.statusCode)) {
        return { error: res.message, data: null };
      }
      return { error: false, data: res.data };
    } catch (error) {
      return { error: error, data: null };
    } finally {
      commit('context/setLoading', false, { root: true });
    }
  },
  [GET_DATA_PREPARE_LIST]: async () => {
    try {
      const res = await $api.get('/Quiz/PrepareSearchFormItem');
      if (![200, 201].includes(res.statusCode)) {
        return { error: res.message, data: null };
      }
      return { error: false, data: res.data };
    } catch (error) {
      return { error: error, data: null };
    }
  },
  [CHANGE_STATUS]: async (context, params = {}) => {
    try {
      const res = await $api.post('/Quiz/ChangeState', params);
      if (![200, 201].includes(res.statusCode)) {
        return { error: res.message, data: null };
      }

      return { error: false, data: res.data };
    } catch (error) {
      return { error: error, data: null };
    }
  },
};

const mutations = {
  [GET_DATA_PREPARE_SUCCESS]: (state, payload) => {
    state.dataPrepare = payload;
  },
  [GET_DATA_PREPARE_FAILED]: (state) => {
    state.dataPrepare = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
