import BranchService from '@/core/services/branch.service';
import ApiService from '@/core/services/api.service';

// action types
export const PUSH_ROUTE_TAB_INDEX = 'pushRouteTabIndex';

// mutation types
export const SET_LOADING = 'setLoading';
export const SET_MODAL = 'setModal';
export const SET_BRANCH = 'setBranch';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_VIDEO_FILE = 'UPLOAD_VIDEO_FILE';
export const UPLOAD_AUDIO_FILE = 'UPLOAD_AUDIO_FILE';
export const SET_UPLOAD_PURCHASED_DELIVERY_BUTTON =
  'setUploadPurchasedDeliveryButton';

export const ADD_ROUTE_TAB_INDEX = 'addRouteTabIndex';
export const SET_ROUTE_TAB_INDEX = 'setRouteTabIndex';

const $api = new ApiService();

const state = {
  loading: false,
  branch: BranchService.getBranch(),
  routeTabIndex: [],
  uploadPurchasedDeliveryButton: false,
  modalShow: false,
};

const getters = {
  loading(state) {
    return state.loading;
  },
  branch(state) {
    return state.branch;
  },
  routeTabIndex(state) {
    return state.routeTabIndex;
  },
  uploadPurchasedDeliveryButton(state) {
    return state.uploadPurchasedDeliveryButton;
  },
};

const actions = {
  [PUSH_ROUTE_TAB_INDEX](context) {
    if (context.state.routeTabIndex.length) {
      context.commit(ADD_ROUTE_TAB_INDEX);
    } else {
      context.commit(SET_ROUTE_TAB_INDEX);
    }
  },
  [UPLOAD_FILE]: async (context, file) => {
    try {
      const payload = new FormData();
      payload.append('file', file);
      const { meta, data } = await $api.post('/File', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (!meta.success) {
        return { error: meta, data: null };
      }
      return { error: false, data };
    } catch (error) {
      return { error, data: null };
    }
  },
  [UPLOAD_VIDEO_FILE]: async (context, params) => {
    try {
      const payload = new FormData();
      payload.append('file', params.file);
      payload.append('containerName', params.containerName);
      const { meta, data } = await $api.post('/File/video', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (!meta.success) {
        return { error: meta, data: null };
      }
      return { error: false, data };
    } catch (error) {
      return { error, data: null };
    }
  },
  [UPLOAD_AUDIO_FILE]: async (context, params) => {
    try {
      const payload = new FormData();
      payload.append('file', params.file);
      payload.append('containerName', params.containerName);
      const { meta, data } = await $api.post('/File/audio', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (!meta.success) {
        return { error: meta, data: null };
      }
      return { error: false, data };
    } catch (error) {
      return { error, data: null };
    }
  },
  [UPLOAD_IMAGE]: async (context, file) => {
    try {
      const payload = new FormData();
      payload.append('images', file);
      const { meta, data } = await $api.post('/Image/Upload', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (!meta.success) {
        return { error: meta, data: null };
      }
      return { error: false, data };
    } catch (error) {
      return { error, data: null };
    }
  },
};

const mutations = {
  [ADD_ROUTE_TAB_INDEX](state) {
    let length = state.routeTabIndex.length;
    state.routeTabIndex.push(length);
  },
  [SET_ROUTE_TAB_INDEX](state) {
    state.routeTabIndex = [0];
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_BRANCH](state, branch) {
    state.branch = branch;
    BranchService.saveBranch(branch);
  },
  [SET_UPLOAD_PURCHASED_DELIVERY_BUTTON](state, isShow) {
    state.uploadPurchasedDeliveryButton = isShow;
  },
  [SET_MODAL]: (state, payload) => {
    state.modalShow = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
