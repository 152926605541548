<template>
  <router-view v-if="signedIn"></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'assets/plugins/flaticon/flaticon.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';

// Main demo style scss
@import 'assets/sass/style.vue';

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from '@/core/services/store/config.module';
import { SET_PARAMS } from '@/core/services/store/setting.module';
import Vue from 'vue';
import ToggleButton from 'vue-js-toggle-button';
Vue.use(ToggleButton);

export default {
  name: 'MetronicVue',
  data() {
    return {
      signedIn: false,
    };
  },
  methods: {
    async handleStorage() {
      await this.delay(2000);
      const permissions = localStorage.getItem('permissions');
      if (!permissions) return this.$mgr.signOut();
    },
  },
  created() {
    window.addEventListener('storage', this.handleStorage);
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.handleStorage);
  },
  mounted() {
    this.$mgr.getSignedIn().then((signIn) => {
      this.signedIn = signIn;
      if (signIn) {
        this.$store.dispatch(SET_PARAMS);
      }
    });
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
};
</script>
