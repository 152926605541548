import ApiService from '@/core/services/api.service';

export const GET_LAST_COMMENT = 'GET_LAST_COMMENT';

export const SET_STATE_MODAL_COMMENT = 'SET_STATE_MODAL_COMMENT';
export const SET_CUSTOMER_INFO = 'SET_CUSTOMER_INFO';
export const SET_LOADING = 'SET_LOADING';
export const GET_LAST_COMMENT_COMPLETED = 'GET_LAST_COMMENT_COMPLETED';

const $api = new ApiService();

const state = {
  showModalComment: false,
  customerInfo: null,
  lastComment: null,
  loadingComment: false,
};
const getters = {};

const actions = {
  [GET_LAST_COMMENT]: async ({ commit }, id) => {
    try {
      commit(SET_LOADING, true);
      const { meta, data, error } = await $api.get(
        `/UserDashboard/Calendar-Training-Latest-Comment/${id}`,
      );
      if (!meta.success) return { error };

      commit(GET_LAST_COMMENT_COMPLETED, data);
    } catch (error) {
      commit(GET_LAST_COMMENT_COMPLETED, null);
      return { error };
    } finally {
      commit(SET_LOADING, false);
    }
  },
};

const mutations = {
  [SET_STATE_MODAL_COMMENT]: (state, payload) => {
    state.showModalComment = payload;
  },
  [SET_CUSTOMER_INFO]: (state, payload) => {
    state.customerInfo = payload;
  },
  [GET_LAST_COMMENT_COMPLETED]: (state, payload) => {
    state.lastComment = payload;
  },
  [SET_LOADING]: (state, payload) => {
    state.loadingComment = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
