import Vue from 'vue';

const mixin = {
  methods: {
    $getPagination(pagination, sort) {
      return {
        orderBy: sort.by ? `${sort.by} ${sort.order}` : null,
        page: pagination.page,
        size: pagination.pageSize,
      };
    },
  },
};

Vue.mixin(mixin);
