import ApiService from '@/core/services/api.service';

// action types
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMER_COUNT = 'GET_CUSTOMER_COUNT';

// mutation types
export const SET_MODAL_ASSIGN = 'SET_MODAL_ASSIGN';
export const SET_MODAL_BOOK = 'SET_MODAL_BOOK';

const $api = new ApiService();

const state = {
  showModalAssign: false,
  showModalBook: false,
};

const getters = {};

const actions = {
  [GET_CUSTOMERS]: async ({ commit, dispatch }, payload = {}) => {
    try {
      commit('context/setLoading', true, { root: true });
      const { params, isLoadCustomerCount } = payload;
      const res = await $api.get('/PackageAccountTransaction/GetPaging', {
        params,
      });

      const data = { ...res };

      if (isLoadCustomerCount) {
        const { newCustomerTotal, waitForClassificationTotal } = await dispatch(
          GET_CUSTOMER_COUNT,
        );
        data.newCustomerTotal = newCustomerTotal;
        data.waitForClassificationTotal = waitForClassificationTotal;
      }

      return {
        error: null,
        data,
      };
    } catch (error) {
      return { error: error, data: null };
    } finally {
      commit('context/setLoading', false, { root: true });
    }
  },
  [GET_CUSTOMER_COUNT]: async () => {
    try {
      const res = await $api.get('/PackageAccountTransaction/GetTotalTabCount');

      return {
        error: null,
        newCustomerTotal: res.newCustomerTotal,
        waitForClassificationTotal: res.waitForClassificationTotal,
      };
    } catch (error) {
      return { error: error, data: null };
    }
  },
};

const mutations = {
  [SET_MODAL_BOOK]: (state, payload) => {
    state.showModalBook = payload;
  },
  [SET_MODAL_ASSIGN]: (state, payload) => {
    state.showModalAssign = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
