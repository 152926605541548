import ApiService from '@/core/services/api.service';
import { sortBy } from 'lodash';
import { MODAL_STATUS } from '../../plugins/constants';

// action types
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const CHANGE_STATUS_SURVEY = 'CHANGE_STATUS_SURVEY';
export const CREATE_QUESTION_GROUP = 'CREATE_QUESTION_GROUP';
export const EDIT_QUESTION_GROUP = 'EDIT_QUESTION_GROUP';
export const GET_QUESTION_GROUP_DETAIL = 'GET_QUESTION_GROUP_DETAIL';
export const GET_SURVEY_DETAIL = 'GET_SURVEY_DETAIL';
export const CREATE_SURVEY = 'CREATE_SURVEY';
export const EDIT_SURVEY = 'EDIT_SURVEY';

// mutation types
export const SET_QUESTIONS_SUCCESS = 'SET_QUESTIONS_SUCCESS';
export const SET_QUESTIONS_ERROR = 'SET_QUESTIONS_ERROR';
export const GET_SURVEY_DETAIL_SUCCESS = 'GET_SURVEY_DETAIL_SUCCESS';
export const GET_SURVEY_DETAIL_ERROR = 'GET_SURVEY_DETAIL_ERROR';
export const RESET_STATE_SURVEY = 'RESET_STATE_SURVEY';
export const ADD_QUESTION_SURVEY = 'ADD_QUESTION_SURVEY';
export const ADD_SECTIONS = 'ADD_SECTIONS';
export const SET_LAST_ORDER = 'SET_LAST_ORDER';
export const DELETE_SECTION = 'DELETE_SECTION';
export const DELETE_QUESTION_SECTION = 'DELETE_QUESTION_SECTION';

const $api = new ApiService();
const state = {
  questions: [],
  surveyDetail: null,
  sections: [],
  lastOrder: 1,
  deletedSections: [],
  deletedQuestions: [],
};

const getters = {};

const actions = {
  [CHANGE_STATUS]: async (context, params = {}) => {
    try {
      const { meta, data, error } = await $api.put(
        `/SurveyQuestionGroup/${params.id}/State`,
        params,
      );
      if (!meta.success) {
        return { error: error.message, data: null };
      }

      return { error: false, data };
    } catch (error) {
      return { error: error, data: null };
    }
  },
  [CHANGE_STATUS_SURVEY]: async (context, params) => {
    try {
      const { meta } = await $api.put(`/Survey/${params.id}/State`, params);
      if (!meta.success) {
        return { error: meta.message, data: null };
      }

      return { error: false, data: null };
    } catch (error) {
      return { error: error, data: null };
    }
  },
  [CREATE_QUESTION_GROUP]: async (context, params) => {
    try {
      const { meta, error, data } = await $api.post(
        '/SurveyQuestionGroup',
        params,
      );
      if (!meta.success) {
        return { error: error, data: null };
      }

      return { error: false, id: data.id };
    } catch (error) {
      return { error: error, id: null };
    }
  },
  [EDIT_QUESTION_GROUP]: async (context, params) => {
    try {
      const { meta, error, data } = await $api.put(
        `/SurveyQuestionGroup/${params.id}`,
        params,
      );
      if (!meta.success) {
        return { error: error, data: null };
      }

      const questions = sortBy(data.questions, 'order');
      questions.forEach((el) => {
        if (el.code.includes('_')) {
          el.source = 'Quiz';
        }
      });
      context.dispatch(GET_QUESTION_GROUP_DETAIL, params.id);
      return { error: false, id: data };
    } catch (error) {
      return { error: error, id: null };
    }
  },
  [GET_QUESTION_GROUP_DETAIL]: async ({ commit }, id) => {
    try {
      const { meta, data, error } = await $api.get(
        `/SurveyQuestionGroup/${id}`,
      );
      if (!meta.success) {
        return { error: error.message, data: null };
      }
      const questions = sortBy(data.questions, 'order');
      questions.forEach((el) => {
        if (el.code.includes('_')) {
          el.source = 'Quiz';
        }
      });
      commit(SET_QUESTIONS_SUCCESS, questions);
      commit(SET_LAST_ORDER, data.questions.length + 1);
      return { error: false, data: data };
    } catch (error) {
      commit(SET_QUESTIONS_ERROR);
      return { error: error.message, data: null };
    }
  },
  [GET_SURVEY_DETAIL]: async ({ commit }, id) => {
    try {
      const { meta, data } = await $api.get(`/Survey/${id}`);
      if (!meta.success) {
        return { error: meta.message, data: null };
      }
      commit(GET_SURVEY_DETAIL_SUCCESS, data);
      return { error: false, data };
    } catch (error) {
      commit(GET_SURVEY_DETAIL_ERROR);
    }
  },
  [CREATE_SURVEY]: async (context, params) => {
    try {
      const { meta, data, error } = await $api.post(`/Survey`, params);
      if (!meta.success) {
        return { error: error.message, data: null };
      }
      return { error: false, data };
    } catch (error) {
      return { error: error.message, data: null };
    }
  },
  [EDIT_SURVEY]: async (context, params) => {
    try {
      const { meta, data, error } = await $api.put(
        `/Survey/${params.id}`,
        params,
      );
      if (!meta.success) {
        return { error: error.message, data: null };
      }
      return { error: false, data };
    } catch (error) {
      return { error: error.message, data: null };
    }
  },
};

const mutations = {
  [SET_QUESTIONS_ERROR]: (state) => {
    state.questions = [];
  },
  [SET_QUESTIONS_SUCCESS]: (state, payload) => {
    state.questions = payload;
  },
  [RESET_STATE_SURVEY]: (state) => {
    state.questions = [];
    state.sections = [];
    state.surveyDetail = null;
    state.lastOrder = 1;
    state.deletedSections = [];
    state.deletedQuestions = [];
  },
  [GET_SURVEY_DETAIL_ERROR]: (state) => {
    state.surveyDetail = null;
  },
  [GET_SURVEY_DETAIL_SUCCESS]: (state, payload) => {
    state.surveyDetail = payload;
    state.sections = sortBy(payload.sections, 'order');
  },
  [ADD_QUESTION_SURVEY]: (state, { id, questions, isEdit }) => {
    const section = state.sections.find((el) => el.id === id);
    section.questions = questions;
    if (isEdit && section?.modelStatus === 0) {
      section.modelStatus = MODAL_STATUS.CHANGED;
    }
  },
  [DELETE_QUESTION_SECTION]: (state, { id, questionId, isEdit }) => {
    const section = [...state.sections].find((el) => el.id === id);
    const deletedItem = section.questions.find((el) => el.id === questionId);
    if (isEdit && deletedItem?.modelStatus === 0) {
      deletedItem.modelStatus = MODAL_STATUS.DELETE;
      state.deletedQuestions.push(deletedItem);
      section.modelStatus = MODAL_STATUS.CHANGED;
    }
    section.questions = section.questions.filter((el) => el.id !== questionId);
  },
  [ADD_SECTIONS]: (state, payload) => {
    state.sections.push(payload);
  },
  [DELETE_SECTION]: (state, { id, isEdit }) => {
    const deletedItem = [...state.sections].find((el) => el.id === id);
    if (isEdit && deletedItem?.modelStatus === 0) {
      deletedItem.modelStatus = MODAL_STATUS.DELETE;
      state.deletedSections.push(deletedItem);
    }
    state.sections = [...state.sections].filter((el) => el.id !== id);
  },
  [SET_LAST_ORDER]: (state, payload) => {
    state.lastOrder = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
