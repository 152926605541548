import ApiService from '@/core/services/api.service';
import { CALENDAR_TYPE } from '@/core/plugins/constants';

// action types
export const GET_CALENDER = 'GET_CALENDER';
export const GET_CALENDER_DETAIL = 'GET_CALENDER_DETAIL';
export const GET_PREPARE_FORM = 'GET_PREPARE_FORM';
export const GET_PREPARE_LIST = 'GET_PREPARE_LIST';
export const GET_PATIENTS = 'GET_PATIENTS';
export const CREATE_CALENDER = 'CREATE_CALENDER';
export const UPDATE_CALENDER = 'UPDATE_CALENDER';
export const IMPLEMENT_CALENDER = 'IMPLEMENT_CALENDER';
export const CANCEL_CALENDER = 'CANCEL_CALENDER';

// mutation types
export const GET_CALENDER_COMPLETED = 'GET_CALENDER_COMPLETED';
export const GET_CALENDER_DETAIL_COMPLETED = 'GET_CALENDER_DETAIL_COMPLETED';
export const GET_PREPARE_FORM_COMPLETED = 'GET_PREPARE_FORM_COMPLETED';
export const GET_PREPARE_LIST_COMPLETED = 'GET_PREPARE_LIST_COMPLETED';
export const CREATE_CALENDER_COMPLETED = 'CREATE_CALENDER_COMPLETED';
export const UPDATE_CALENDER_COMPLETED = 'UPDATE_CALENDER_COMPLETED';
export const SET_MODAL = 'SET_MODAL';
export const SET_LOADING = 'SET_LOADING';
export const RESET_STATE = 'RESET_STATE';

// API
const $api = new ApiService();
const API_URL = {
  LIST: '/Calendar',
  DETAIL: '/Calendar',
  PREPARE_FORM: '/Calendar/PrepareCreateUpdateFormItem',
  PREPARE_LIST: '/Calendar/PrepareSearchFormItem',
  CREATE: '/Calendar',
  UPDATE: '/Calendar',
  IMPLEMENT: '/Calendar',
  CANCEL: '/Calendar',
};

const state = {
  modalCalender: false,

  // Data List
  calenders: [],
  listCoaches: [],
  listTypes: [],
  activites: [],

  // Data Form
  calendarId: '',
  calendar: {},
  coaches: [],
  performers: [],
  patients: [],
  formRepeatTypes: [],
  formTypes: [],
  loading: false,
  hostZooms: [],
};

const getters = {};

const actions = {
  [GET_CALENDER]: async ({ commit }, params) => {
    try {
      const { data, meta } = await $api.get(API_URL.LIST, {
        params,
      });
      if (!meta.success) return;

      commit(GET_CALENDER_COMPLETED, data);
    } catch (error) {
      commit(GET_CALENDER_COMPLETED);
      console.error(error);
    }
  },

  [GET_CALENDER_DETAIL]: async ({ commit }, params) => {
    try {
      const { data, meta } = await $api.get(`${API_URL.DETAIL}/${params.id}`);
      if (!meta.success) return;

      commit(GET_CALENDER_DETAIL_COMPLETED, data);
    } catch (error) {
      commit(GET_CALENDER_DETAIL_COMPLETED);
      console.error(error);
    }
  },

  [GET_PREPARE_FORM]: async ({ commit }, params) => {
    try {
      const { data, meta } = await $api.get(API_URL.PREPARE_FORM, {
        params,
      });
      if (!meta.success) return;

      commit(GET_PREPARE_FORM_COMPLETED, data);
    } catch (error) {
      commit(GET_PREPARE_FORM_COMPLETED);
      console.error(error);
    }
  },

  [GET_PREPARE_LIST]: async ({ commit }, params) => {
    try {
      const { data, meta } = await $api.get(API_URL.PREPARE_LIST, {
        params,
      });
      if (!meta.success) return;

      commit(GET_PREPARE_LIST_COMPLETED, data);
    } catch (error) {
      console.error(error);
    }
  },

  // eslint-disable-next-line no-unused-vars
  [GET_PATIENTS]: async ({ commit }, params) => {
    const { data } = await $api.get('/GroupDashboard', {
      params,
    });
    return data?.items;
  },

  [CREATE_CALENDER]: async ({ commit }, params) => {
    const { data, meta } = await $api.post(API_URL.CREATE, params);
    if (meta.success) {
      commit(CREATE_CALENDER_COMPLETED, data);
      return { data };
    }

    commit(CREATE_CALENDER_COMPLETED);
    throw new Error('Tạo mới lịch hẹn không thành công');
  },

  [UPDATE_CALENDER]: async ({ commit }, params) => {
    try {
      const { meta } = await $api.put(`${API_URL.UPDATE}/${params.id}`, params);
      if (!meta.success) return;

      // commit(UPDATE_CALENDER_COMPLETED, data);
    } catch (error) {
      commit(UPDATE_CALENDER_COMPLETED);
      console.error(error);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  [CANCEL_CALENDER]: async ({}, { id, ...params }) => {
    try {
      await $api.delete(`${API_URL.CANCEL}/${id}`, {
        params,
      });
    } catch (error) {
      console.error(error);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  [IMPLEMENT_CALENDER]: async ({}, { id }) => {
    try {
      await $api.put(`${API_URL.IMPLEMENT}/${id}/Complete`);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  [GET_CALENDER_COMPLETED]: (state, payload = []) => {
    state.calenders = payload.map((item) => ({
      ...item,
      start: new Date(item.appointmentDate * 1000),
      end: new Date((item.appointmentDate + item.duration) * 1000),
      timed: true,
    }));
  },

  [GET_CALENDER_DETAIL_COMPLETED]: (state, payload = {}) => {
    state.calendar = { ...payload };
  },

  [GET_PREPARE_FORM_COMPLETED]: (state, payload = {}) => {
    state.calendarId = payload.calendarSchedulerId;
    state.coaches = payload.trainingGroups;
    state.patients = payload.patients || [];
    state.formRepeatTypes = payload.calendarRepeatTypes || [];
    state.formTypes = payload.calendarTypes || [];
    state.performers = payload.coaches || [];
    state.activites = payload.calendarActive || [];
    state.hostZooms = payload.hostZooms || [];
  },

  [GET_PREPARE_LIST_COMPLETED]: (state, payload = {}) => {
    state.listCoaches = [...payload.coaches];
    state.hostZooms = [...payload.hostZooms];
    state.listTypes =
      payload.calendarTypes.map((item) => {
        const color = Object.values(CALENDAR_TYPE).find(
          (type) => type.value === item.value,
        )?.color;
        return { ...item, color };
      }) || [];
  },

  [CREATE_CALENDER_COMPLETED]: (state, payload = {}) => {
    state.calenders = [
      ...state.calenders,
      {
        ...payload,
        start: new Date(payload.appointmentDate * 1000),
        end: new Date((payload.appointmentDate + payload.duration) * 1000),
        timed: true,
      },
    ];
  },

  [UPDATE_CALENDER_COMPLETED]: (state, payload = {}) => {
    if (!payload.id) return;

    for (let i = 0; i < state.calenders.length; i++) {
      if (state.calenders[i].id === payload.id) {
        state.calenders.splice(i, 1, payload);
        break;
      }
    }
  },

  [SET_MODAL]: (state, [variable, value]) => {
    state[variable] = value;
  },

  [RESET_STATE]: (state) => {
    state.calenders = [];
    state.modalCalender = false;
  },

  [SET_LOADING]: (state, payload) => {
    state.loading = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
